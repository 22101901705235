<template>
  <div id="Setting">
    <div>
      <div>
        <div>是否公开联系方式</div>
        <van-switch v-model="isContactShow" active-color="#34C759" size="0.2rem" @input="onInput" />
      </div>
      <div>
        <div>是否接受消息推送</div>
        <van-switch v-model="isAcceptPush" active-color="#34C759" size="0.2rem" @input="onInput1" />
      </div>
      <div>
        <p>需要关注“保观大咖会”微信公众号，才能正常接收消息推送</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Setting",
  data() {
    return {
      isContactShow:false,
      isAcceptPush:false

    };
  },
  mounted() {
    this.axios({
      method: 'GET',
      url: '/user/privacyDetail',
      headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
    }).then((res) => {
      if(res.data.code == 0){
        if(res.data.data.isContactShow){
          this.isContactShow = true  //公开  1，不公开0
        }else{
          this.isContactShow = false
        }
        if(res.data.data.isAcceptPush){
          this.isAcceptPush = true
        }else{
          this.isAcceptPush = false
        }
      }
    })
  },
  methods: {
    onInput(checked){
      var status = 0
      if(checked){
        status = 1
      }else{
        status = 0
      }
      this.axios({
        method: 'PATCH',
        url: '/user/updateUserPrivacy',
        headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
        data:{
          type:'isContactShow',
          status:status
        }
      }).then((res) => {
        if(res.data.code == 0){
          this.isContactShow = checked;
        }
      })
    },
    onInput1(checked){
      var status = 0
      if(checked){
        status = 1
      }else{
        status = 0
      }
      this.axios({
        method: 'PATCH',
        url: '/user/updateUserPrivacy',
        headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
        data:{
          type:'isAcceptPush',
          status:status
        }
      }).then((res) => {
        if(res.data.code == 0){
          this.isAcceptPush = checked;
        }
      })
    },
     // 跳转大咖详情
    toIntroDetail(id){
      this.axios({
        method: 'PATCH',
        url: '/master/alwaysVisitAdd',
        headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
        data:{
          masterId:id
        }
      }).then((res) => {
        if(res.data.code == 0){
          this.$router.push({name:'Introduction',query: {id:id}})
          
        }
      })
    },
  },
};
</script>
<style lang="less" scoped>
#Setting {
  height: 100vh;
  background: #16171D;
  >div{
    padding: 0 0.15rem;
    background: #23252E;
    >div{
      padding-top: 0.15rem ;
      display: flex;
      justify-content: space-between;
      align-items: center;
      >div:first-child{
        color: #ECEDF7;
        font-size: 0.14rem;
      }
      >p{
        font-size: 0.12rem;
        color: #727387;
      }
    }
    >div:first-child{
      padding-bottom: 0.15rem;
      border-bottom: .1px solid #5C5D6E61;
    }
    >div:last-child{
      padding-top: 0.05rem;
      padding-bottom: 0.15rem;
    }
  }
}
</style>